const getCookie = cookieName => {
    const allCookies = document.cookie.split('; ');
    const cookie = allCookies.find(item => item.includes(cookieName));

    if(cookie){
        const cookieValue = cookie.replace(`${cookieName}=`, '');

        return cookieValue;
    }
    return null;
};

export default getCookie;