import getCookie from '~/js/views/get-cookie';

const aboutLinks = document.querySelectorAll("[data-links='ssg-footer'] li" );

const isFromCalifornia = getCookie('isFromCalifornia') !== null && getCookie('isFromCalifornia') === 'true';

for (const linkItem of aboutLinks) {
    const linkItemText = linkItem.innerText.toLowerCase();

    if (linkItemText === 'do not sell or share my personal data' && !isFromCalifornia) {
        linkItem.classList.add('hide-item');
    }

}

